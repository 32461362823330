import React from "react";
import "../../../_metronic/_assets/sass/pages/error/error-3.scss";

export function ErrorPage3() {
	return (
		<div className="error-3-display">
			<div className="kt-grid kt-grid--ver kt-grid--root">
				<div className="container-error">
					<div className="container-error-1" >
						<img src="/images/new_logo.png" className="error-logo" />
						<p className="error-code" ><b style={{ color: '#284EEA', fontWeight: 600 }} >403.</b> Acesso proibido.</p>
						<p className="error-title" >Desculpe, você não possui pemissão para acessar esta página.</p>
						<p className="error-text" >Entre em contato com um administrador para solicitar permissão, ou, caso você seja um administrador, vá até <b>Minha empresa → Grupos de acesso</b> e conceda permissão a esta página.</p>
						<a href="/">
							<div className="error-button" >Voltar ao início</div>
						</a>
					</div>
					<div className="container-error-2" >
						<img src="/media/error/side_image_403.png" className="error-image" width={536} />
					</div>
				</div>
			</div>
		</div>
	);
}